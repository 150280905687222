<template>
  <div
    class="Navigation"
    :class="{ Width1000: width === '1000' }">
    <span
      class="NavItem"
      v-for="item in navigationItems"
      :key="item.pathName"
      :class="{Active: item.pathName === currentRoute.name, IsDisabled: item.isDisabled}"
      @click="onNavClick(item)">
      {{ item.title }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TabNavigation',
  props: {
    navigationItems: {
      type: Array,
      required: true,
    },
    width: {
      type: String,
      required: false,
      default: 'default', // 'default' | '1000'
    },
  },
  computed: {
    ...mapGetters([
      'currentScreening',
    ]),
    currentRoute() {
      return this.$route
    },
  },
  methods: {
    onNavClick(item) {
      if (item.isDisabled) {
        return
      }
      this.$router.push({
        name: item.pathName,
        params: item.params,
      })
    },
  },
}
</script>

<style lang="stylus" scoped>

  .Navigation
    display flex
    margin 20px auto 0px
    overflow auto
    overflow-y hidden
    max-width 1200px
    &.Width1000
      max-width 1000px

  .NavItem
    padding 10px 15px
    text-transform uppercase
    cursor pointer
    margin-bottom 0px
    transition all 0.15s ease-out
    border-bottom 2px solid transparent
    white-space nowrap
    &:hover
      border-bottom 2px solid $color_grey_light
    &.Active
      border-bottom 2px solid $color_salmon_darkest
      color $color_salmon_darkest
    &.IsDisabled
      opacity 0.5
      cursor default
      &:hover
        border-bottom none
</style>
