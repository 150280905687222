<template>
  <div class="OverviewHeaderMenu">
    <DotMenuItem
      :text="mixWB('SHOW_IN_BACKEND')"
      itemID="show-in-backend"
      @dot-item-click="onDotItemClick" />
    <DotMenuItem
      :text="mixWB('USE_AS_DEMO_PROJECT')"
      itemID="make-demo-project"
      @dot-item-click="onDotItemClick" />
  </div>
</template>

<script>
import DotMenuItem from '@/components/DotMenu/DotMenuItem.vue'

export default {
  name: 'OverviewHeaderMenu',
  methods: {
    onDotItemClick(itemID) {
      this.$emit('pass-to-parent', { name: 'menu-click', value: itemID })
      this.$emit('close-menu')
    },
  },
  components: { DotMenuItem },

}
</script>

<style lang="stylus" scoped>
  .OverviewHeaderMenu
    display block
</style>
