<template>
  <div class="Header">
    <!-- Top -->
    <div class="Top">
      <div class="LeftSide">
        <span class="Title">
          {{ currentScreeningData.getAddressLine() }}
        </span>
        <span v-if="caseNumberAndSamplingIDText">
          {{ mixWB('CASE_NUMBER') }}: {{ caseNumberAndSamplingIDText }}
        </span>
        <span v-if="currentScreeningData.enterpriseID">
          {{ mixWB('ENTERPRISE_ID') }}: {{ currentScreeningData.enterpriseID }}
        </span>
      </div>
      <div class="RightSide">
        <div
          v-if="currentScreening && currentScreeningData"
          class="ChipList">
          <DotMenu
            v-if="currentUser.isDeveloper"
            :use-component="OverviewHeaderMenu"
            position="small-button-list"
            @menu-click="onMenuClick" />
          <Chip
            v-if="currentScreeningData.isStarted"
            :text="currentScreeningData.getScreeningTimeUsed()"
            :icon="StopWatchIcon" />
          <Chip
            :text="currentScreeningData.getStatus().text"
            :icon="LightningIcon" />
          <Chip
            v-if="screener"
            :text="screener.fullName"
            :icon="UserIcon" />

          <Chip
            :text="mixGetDate(metaData.createdAt, 'LL')"
            :icon="CalendarIcon" />
          <Chip
            v-if="currentScreeningData.isArchived"
            :text="mixWB('ARCHIVED')"
            :icon="ArchivedIcon" />

        </div>

        <div
          v-if="currentScreeningData"
          class="ButtonsList">
          <Button
            :path="{
              name: 'ScreeningsUpdate',
              params: { screeningID: this.currentScreening.id },
            }"
            :text="mixWB('EDIT')"
            size="small"
            :fullWidth="false" />
          <Button
            :href="currentScreeningData.getMilvaOnlineLink()"
            :text="mixWB('VIEW_IN_MILVA_ONLINE')"
            size="small"
            isExternal
            :fullWidth="false" />
          <Button
            v-if="showArchiveButton"
            :text="mixWB('ARCHIVE')"
            size="small"
            :fullWidth="false"
            @button-click="onArchiveClick" />
          <Button
            v-if="showUnarchiveButton"
            :text="mixWB('MAKE_ACTIVE_AGAIN')"
            size="small"
            :fullWidth="false"
            @button-click="onUnarchiveClick" />
        </div>
      </div>
    </div>

    <!-- Navigation -->
    <TabNavigation
      v-if="currentScreening.id"
      :navigationItems="navigationItems" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Screening from '@/globals/javascript/models/Screening'
import Button from '@/components/Buttons/Button.vue'
import Chip from '@/components/Chips/Chip'
import LightningIcon from '@/assets/svg/lightning.svg?inline'
import StopWatchIcon from '@/assets/svg/stop-watch.svg?inline'
import ArchivedIcon from '@/assets/svg/archived-screenings.svg?inline'
import CalendarIcon from '@/assets/svg/calendar.svg?inline'
import UserIcon from '@/assets/svg/user.svg?inline'
import TabNavigation from '@/components/TabNavigation.vue'
import DotMenu from '@/components/DotMenu/DotMenu.vue'
import OverviewHeaderMenu from '@/components/ScreeningItems/Menus/OverviewHeaderMenu'

export default {
  name: 'Header',
  data() {
    return {
      LightningIcon,
      StopWatchIcon,
      ArchivedIcon,
      CalendarIcon,
      UserIcon,
      OverviewHeaderMenu,
    }
  },
  computed: {
    ...mapGetters([
      'currentScreening',
      'currentScreeningData',
      'metaData',
      'allUsers',
      'currentUser',
      'caseNumberAndSamplingIDText',
      'standardSamplesStatus',
      'currentAccount',
      'isDemoUser',
    ]),
    navigationItems() {
      return [
        {
          title: this.mixWB('OVERVIEW'),
          pathName: 'ResultOverview',
          params: { screeningID: this.currentScreening.id },
          isDisabled: false,
        },
        {
          title: this.mixWB('REQUISITION'),
          pathName: 'ResultRequisition',
          params: { screeningID: this.currentScreening.id },
          isDisabled: false,
        },
        {
          title: this.mixWB('SAMPLES'),
          pathName: 'ResultSamples',
          params: { screeningID: this.currentScreening.id },
          isDisabled: false,
        },
        {
          title: this.mixWB('UPLOAD_FILES'),
          pathName: 'FileUpload',
          params: { screeningID: this.currentScreening.id },
          isDisabled: false,
        },
      ]
    },
    screener() {
      return this.allUsers.find((x) => x.id === this.currentScreeningData.userID)
    },
    isRequisitionButtonDisabled() {
      if (!this.currentScreeningData?.appVersion) {
        return true
      }
      if (!this.currentScreeningData.isCompleted) {
        return true
      }
      if (!this.standardSamplesStatus.totals.required) {
        return true
      }
      return false
    },
    showArchiveButton() {
      if (this.isDemoUser) {
        return false
      }
      if (!this.currentScreeningData || this.currentScreeningData.isArchived) {
        return false
      }

      return true
    },
    showUnarchiveButton() {
      if (this.isDemoUser) {
        return false
      }
      if (!this.currentScreeningData || !this.currentScreeningData.isArchived) {
        return false
      }

      return true
    },
  },
  methods: {
    onMenuClick(value) {
      if (value === 'show-in-backend') {
        this.onShowInBackendClick()
      }
      if (value === 'make-demo-project') {
        const answer = window.confirm(
          this.mixWB('ARE_YOU_SURE'),
        )
        if (answer) {
          this.$store.dispatch('makeDemoProject')
        }
      }
    },
    onArchiveClick() {
      this.$store.dispatch('updateScreening', {
        setAsArchived: true,
      })
    },
    onUnarchiveClick() {
      this.$store.dispatch('updateScreening', {
        setAsActive: true,
      })
    },
    onShowInBackendClick() {
      Screening.showInBackend(this.currentScreening.id)
    },
  },
  components: {
    Chip,
    Button,
    TabNavigation,
    DotMenu,
  },
}
</script>

<style lang="stylus" scoped>
  .Header
    display block
    padding 20px 20px 0px
    margin-bottom 30px
    background-color $color_grey_lightestest
    border-bottom 1px solid $color_grey_lighter

  .Top
    display flex
    justify-content space-between
    max-width 1200px
    margin 0 auto
    +below($tablet)
      display block

  .LeftSide
    flex-grow 2
    min-width 45%
    span
      display block
      padding-bottom 5px
    .Title
      font-size 2rem
      padding-bottom 3px
    +below($tablet)
      margin-bottom 10px
      .Title
        font-size 1.5rem
        font-weight bold

  .RightSide
    flex-shrink 0
    display flex
    flex-direction column
    align-items flex-end
    max-width 55%
    +below($tablet)
      max-width 100%
      display block

  .ChipList
    display flex
    flex-wrap wrap
    justify-content flex-start
    flex-direction row-reverse
    margin-bottom 10px
    max-width 600px
    > div
      margin 0 0 5px 5px
    +below($tablet)
      justify-content flex-start
      flex-direction row
      margin-bottom 15px
      flex-wrap wrap
      > div
        margin 0 5px 5px 0

  .ButtonsList
    display flex
    flex-wrap wrap
    justify-content flex-end
    >>> .ButtonWrap
      display inline-block
      margin 0 0px 10px 10px
    .TestDataUploadWrap
      margin-bottom 0
    +below($tablet)
      justify-content flex-start
      >>> .ButtonWrap
        margin 0 10px 10px 0

  .TestDataUploadWrap
    input
      display none
</style>
